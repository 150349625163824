<template>
  <sdPageHeader title="Participant Campaign List">
  </sdPageHeader>
  <Main>
  <sdCards headless>
    <a-row>
      <a-col :md="24">
        <h2>Total Campaign - {{totalData}}</h2><br>
      </a-col>
    </a-row>
    <a-row :gutter="25">
      <a-col :md="24">
          <TableWrapper class="table-order table-responsive">
            <a-table
              rowKey="id"
              :columns="fields"
              :dataSource="campaigns"
              @change="handleTableChange"
              :pagination="{ pageSize: perPage, showSizeChanger: false, total: totalData}"
            >
            <template #name="{record}">
              <div v-if="record.name">
                <a @click="navigateTo(`/${initial}/campaigns/details/${record.campaign_encrypt_id}`)">{{ record.name }}</a>
              </div>
            </template>
            <template #participate_date="{text: participate_date}">
              <div v-if="participate_date">
                {{ getFormatedDate(participate_date) }}
              </div>
            </template>
            <template #is_winner="{text: is_winner}">
              <span :class="['status', getStatusColor(is_winner)]">{{participant_status[is_winner]}}</span>
            </template> 
             <template #action="{record}">
              <div class="table-actions">
                <sdButton @click="navigateTo(`/${initial}/participants/details/${record.participant_encrypt_id}`)" class="btn-icon" type="primary" shape="circle">
                  <sdFeatherIcons type="eye" size="16" />
                </sdButton>
              </div>
            </template>       
            </a-table>
          </TableWrapper>
      </a-col>
    </a-row>
  </sdCards>
  </Main>
  
</template>
<script>
import moment from "moment";
import Notification from "@/services/Notification";
import { API } from "@/services/api";
import { Main } from '../../styled';
import { status } from '@/config/config.js';

export default {
  name: "SubscriberCamapaign",
  components: {Main},
  computed: {
    initial: function() {
      return this.$store.getters.initial;
    },
    participant_status: function() {
      return status;
    }
  },
  data() {
    return {
      search: "",
      status: "",
      fetching: true,
      perPage: 10,
      totalData: 0,
      subscriber: {},
      campaigns: [],
      email: "",
      fields: [
        { title: 'Id',dataIndex: 'id', key: 'id',sorter : true,sort_index:0},
        { title: 'Campaign', dataIndex: 'name',key: 'name',sorter : true,
          slots: { customRender: 'name' },sort_index:1
        },
        { title: 'Name', dataIndex: 'display_name',key: 'display_name',sorter : true,sort_index:6},
        { title: 'Email', dataIndex: 'email',key: 'email',sorter : true,sort_index:7},
        { title: 'Game Name', dataIndex: 'game_name',key: 'game_name',sorter : true,sort_index:2},
        { title: 'Theme Name', dataIndex: 'theme_name',key: 'theme_name',sorter : true,sort_index:3},
        { title: 'Date', dataIndex: 'participate_date',key: 'participate_date',
          slots: { customRender: 'participate_date' },sorter : true,sort_index:4
        },
        { title: 'Status', dataIndex: 'is_winner', key: 'is_winner',
          slots: { customRender: 'is_winner' },sorter : true,sort_index:5
        },
        { title: 'Action', dataIndex: 'action', key: 'action',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  methods: {
        navigateTo: function (path = "") {
            if (path) {
                //alert(path);
                this.$router.replace({path});
            }
        },
      async getSubscriberDetails() {
      try {
        const { subscriber_id = "" } = this.$route.params;
        const res = await API.getSubacriberDetail({params: {
          id: subscriber_id,
        }});
        const { data, message, status } = res?.data ?? {};
        if (status) {
          this.subscriber = data;
          this.email = data.email;
          this.getParticipantCampaigns();
        } else if(!status && message) {
          Notification.show(message, {type: "error",title: "Error"});
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error",title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    async getParticipantCampaigns() {
      try {
        const params = {
          email: this.email,
          page: this.currentPage,
          limit: this.perPage,
          search: this.search,
          status: this.status,
          sort_field: this.sortField,
          sort_order: this.sortOrder,
        };
        const datRes = await API.getSubacriberCampaign({ params });
        const { data: sData, status, message } = datRes.data;
        if (!status && message) {
          Notification.show(message, {type: "error",title: "Error"});
        } else {
          this.campaigns = sData.campaign;
          this.totalData = sData.total_rows;
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error",title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    handleTableChange(page, filters, sorter) {
      if (this.currentPage != page.current) {
        this.currentPage = page.current;
        setTimeout(() => {
          this.getParticipantCampaigns();
        }, 300);
      }
      if(typeof sorter.column.sort_index != "undefined" && sorter.order != "" ) {
        this.sortField = sorter.column.sort_index;
        this.sortOrder = "asc";
        if(sorter.order != "" && sorter.order == "descend") {
          this.sortOrder = "desc";
        }
        this.getParticipantCampaigns();
      }
    },
    getFormatedDateTime(date = "") {
      return moment(date).format("LLL");
    },
    onSearching(text) {
      this.search = text;
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getParticipantCampaigns();
      }, 500);
    },
    getFormatedDate(date = "") {
      return moment(date).format("LL");
    },
    getStatusColor(status) {
      if(status == 1) {
        return 'Success';
      } else if(status == 0) {
        return 'error';
      } else if(status == 2) {
        return 'warning';
      } else {
        return '';
      }
    }
  },
  created() {
    this.getSubscriberDetails();
  }
}

</script>
<style>
  div.status {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 14px;
  }
   table .table-actions button {
        height: 40px;
        padding: 0 11px;
        background: transparent;
        border: 0 none;
        color: #ADB4D2;
    }
      
</style>